
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import ResponsibleRummy from "./pages/ResponsibleRummy";



import "./App.css";

const Home = () => <div className="banner"
><h2 className="heading"> WELCOME TO BGM GAME

  </h2>
  <img src="/rummy2pic.png" alt="Home" className="home-img" />
</div>;




const cellStyle = {
  padding: "5px 5px",
  fontWeight: "700",
  border: "1px solid #bbb8b8",
  borderCollapse: "collapse",
};

const GameRule = () => <div className="game-rule">
  <div className="game-heading"><h1>Game Rules</h1></div>


<h2>How to Play Rummy Card Game: Get Started with Rummy Rules</h2>
  <p>Rummy is a card game that is played with two decks of cards with total of two Jokers. To win the rummy game a player must make a valid declaration by picking and discarding cards from the two piles given. One pile is a closed deck, where a player is unable to see the card that he is picking, while the other is an open deck that is formed by the cards discarded by the players. To win at rummy card game, the players have to group cards in valid sequences and sets.In rummy, the cards in each suit rank low to high starting with Ace, 2, 3, 4, 5, 6, 7, 8, 9, 10, Jack, Queen and King. Ace, Jack, Queen and King each has 10 points. The remaining cards have value equal to their face value. For example, 5 cards will have 5 points and so on. </p>

  <h2>The Objective Of Rummy:</h2>
  The objective of rummy card game is to arrange the 13 cards in valid sets and sequences. To win the game you need to make minimum 2 sequences, out of which one needs to be pure sequence and rest can be any valid sequence or sets. Without a pure sequence you cannot make a valid rummy declaration. This is one of the most important rummy rules.
  <h2>How to Form Sequences?</h2>
  In rummy, a sequence is a group of three or more consecutive cards of the same suit. There are two types of sequences that are formed; a pure sequence and an impure sequence. To win the game of rummy you need at least one pure sequence in your rummy hand.
  <h2>Pure Sequence</h2>
  A pure sequence is a group of three or more cards of the same suit, placed in consecutive order. To form a pure sequence in rummy card game, a player cannot use any Joker or wild card.
  Here are a few examples of pure sequence.

  5♥ 6♥ 7♥ (Pure sequence with three cards and there is no Joker or wild card used)
  3♠ 4♠ 5♠ 6♠ (Pure sequence with four cards. There is no use of Joker or wild cards here.)

  Impure Sequence
  An impure sequence is a group of three or more cards of the same suit with one or more Joker card used.
  Here are some examples to show how an impure sequence is formed.

  6♦ 7♦ Q♠ 9♦ (Here Q♠ has been used as a wild Joker replacing 8♦ to form an impure sequence.)
  5♠ Q♥ 7♠ 8♠ PJ (Impure sequence with Q♥ as wild joker that is replacing 6♠ and the Printed Joker is replacing 9♠.)
  <h2>How to Form Sets?</h2>

  A set is a group of three or more cards of the same value but of different suits. When you are forming sets, you can use wild card and Jokers.
  <p>Examples of sets</p>
  <p>1.A♥ A♣ A♦ (In this set, all the Ace are of different suits, make a valid set.)</p>
  <p>2.8♦ 8♣ 8♠ 8♥ (Rummy set is formed with four 8 cards of different suits.)</p>
  <p>3.9♦ Q♠ 9♠ 9♥ (Here Q♠ has been used as wild joker replacing 9♣ to make set.)</p>
  <p>4.5♦ 5♣ 5♠ PJ (Printed joker replacing 5♥ to make set.)</p>
  <p>5.5♦ 5♣ Q♠ PJ (Here Q♠ has been used as wild joker replacing 5♠ & Printed joker replacing 5♥ to make set.)</p>
  <p>6.5♦ 5♣ PJ Q♥ Q♠ (This is a set of 5 cards with Printed joker & Q♥ as wild joker replacing 5♠ 5♥ and one more wild joker Q♠ to complete 13 cards grouping.)</p>
  <p>Typical Example: 2♥ 3♥ 4♥ 5♥| 5♣ 6♣ 7♣ 8♣ | 5♦ 5♣ PJ Q♥ Q♠ (Set of 5 cards is made to complete the grouping of 13 cards and make a valid declaration)</p>
  <p>Note: The set is formed with same card of different suits. However, you can’t use two or more cards of the same suit. This is taken as an invalid declaration. Also, note that a set can have more than four cards. So, if you have a set of four cards and you are using an additional Joker, then in total it becomes a 5 cards group and still be a valid set. At no given time, the hand can have more than 13 cards.</p>
  <p>Examples of invalid set</p>
  <p>1.Q♥ Q♥ Q♦ (There are two Qs of the same suit ♥ making it an invalid set.)</p>
  <p>2.7♠ 7♥ 7♦ 7♠ Q♥ (It has two 7 spades of the same suit. The wild card Q♥ as the fifth card is valid but having two 7♠ is making it invalid.)</p>
  <h2>How To Play Rummy Card Game?</h2>
  <p>Follow this simple rummy rules and instructions to know how to play the game from start to end:</p>
  <p>1.Rummy card game is played between 2 to 6 players with 2 decks of cards. Each player is dealt with 13 cards and a random card is selected as wild joker or joker card of the game.</p>
  <p>2.The player has to draw and discard cards to form valid sets and sequences of the 13 cards in hand where player can also use wild joker or printed joker of the deck to make impure sequence and sets.</p>
  <p>3.As per indian rummy rules, once a player arranged 13 cards in valid 2 sequences including 1 pure sequence and more groups (sequences or sets), one can make a declaration and win the game.</p>

  <h2>Quick Tips To Win The Rummy Card Game</h2>

  <ul>
    <li>Form the pure sequence at the very beginning of the game. Without a pure sequence, a player cannot make a declaration.</li>
    <li>Discard cards with high points like Ace, Jack, Queen and King. Replace these cards with Joker or Wild Cards. It reduces the point load, in case you lose the game.</li>
    <li>As much as possible, avoid picking from the discard pile. It gives away what hand you are trying to form.</li>
    <li>Look out for smart cards. For example, a 7 of any suit can work with 5 and 6 of the same suit and also 8 and 9 of the same suit.</li>
    <li>Jokers play an important role in rummy. Try using them to replace high value cards. Remember, Joker and wild cards cannot be used to form pure sequence.</li>
    <li>When you are ready to make a declaration, check and recheck your cards and then press the button. An invalid declaration can turn even a winning game into a complete loss.</li>
  </ul>
  <div>
    <h2>What is a Rummy Table?</h2>
    <p>It is the table where the game of rummy is played. Every rummy table can sit two to six players for each game.</p>
    <h2>What is Joker and Wild Cards?</h2>
    <p>In each rummy deck there is a Printed Joker and there is a Wild Card that is selected at random at the beginning of the game. The role of both these types of card is the same. Jokers are used to form sets and impure sequences. A Joker card can replace the desired number when forming the groups. This is a valid formation in a rummy game.</p>
    <h2>What is Draw and Discard?</h2>
    <p>In all Rummy games, each player is dealt 13 cards. Additionally, there are 2 stacks from which each player can select cards, thereby drawing a card. Once a player draws a card, he has to get rid of one card - this is called discarding.</p>
    <h2>What is Sorting of Cards?</h2>
    <p>Sorting of cards are done at the beginning of the game. This is done to arrange your cards to help you form your sets and sequences reducing the probability of mixing the cards. Once, the cards are displayed, you can hit the Sort button and start playing.</p>
    <h2>What is a Drop?</h2>
    <p>When a player decides to leave the game table at the start or middle of the rummy game, it is a drop. The act is withdrawing from the game as a personal decision. The first drop=20 points; middle drop= 40 points and last drop and maximum point loss is 80 points.</p>
    <p>In case of Pool rummy, if a player drops in 101 pool, the score is 20. In case, it is 201 pool rummy, the drop score is 25. In a game, where it is best of 2 and best of 3 is played, then a drop is not allowed.</p>
    <h2>What are Cash Tournaments?</h2>
    <p>Cash tournaments are those that are played for Real Cash and have Real Cash Prizes (in INR). These tournaments run 24x7 and are conducted in a knock-out style. To play any of the cash games, the player has to add Cash to its BGM Game account.</p>
    <h2>How do I Join a Tournament?</h2>
    <p>Go to 'Tournaments' in the top navigation panel. Now, select the type of Tournament you wish to play. In the corresponding Tournament List, click on any of the Open tournaments that you wish to join. Finally, click on the blinking Join This Tournament button under Tournament Details.</p>
    <h2>What is an Invalid Declaration?</h2>
    <p>An invalid declaration happens in rummy card game when the player presses the Declaration button, but the cards are not in valid sequences and sets. Therefore, the player will lose the game and the competitor will automatically be declared as the winner</p>
    <p>Here are few examples of common invalid declarations players make while playing rummy:</p>
    <div>
      <div style={{ display: "flex ", gap: "5px" }}>
        <h4>A.</h4>
        <h3>Wrong Declaration with Invalid Sets</h3>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <p>Example 1: 10♠ 10♠ 10♦ 10♣ Q♥</p>
        <p>A set can have 3 cards or more, however a set needs to be formed with cards of the same value and different suits. In this situation, the Wild Joker (Queen of Hearts) card was added and that became the fifth card, which is valid as per rules, however the group consist of 2 cards of the same suit making it a wrong declaration.</p>
        <p>Example 2: K♥ K♥ K♦</p>
        <p>In this set, there are 3 cards which is well within the minimum limit. Also, a set consists of cards of the same face value but has to be of different suits. The set cannot have more than one card of the same suit. In this example, the set consists of two cards of the same suit and that makes it a wrong declaration.</p>
      </div>

      <div style={{ display: "flex ", gap: "5px", alignItems: "center" }}>
        <h4>B.</h4>
        <h3>Wrong Declaration with Invalid Sequences</h3>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <p>Example 1: 10♠ 10♥ 10♦ 10♣ | 5♠ 5♥ 5♦ | 6♠ 6♥ 6♣ | 9♥ 9♦ Joker

        </p>
        <p>A valid declaration requires 2 sequences, out of which one needs to be pure sequence i.e. sequence without joker and the other can be pure or impure sequence i.e. sequence with or without joker. However, in the given example there is neither sequence made making it an invalid declaration.

        </p>
        <p>Example 2: K♥ K♠ K♦ | 6♥ 7♥ Joker | 9♠ 10♠ J♠ Joker | 5♠ 5♥ 5♦</p>
        <p>A valid declaration must have 2 sequences, out of which one needs to be pure sequence i.e. sequence without joker and the other can be pure or impure sequence i.e. sequence with or without joker. This example shows that there are 2 sequences, but both are impure sequence i.e. sequence with joker and the pure sequence is not there. It is compulsory to have a pure sequence before you make a declaration.</p>
        <p>Example 3: Q♥ Q♠ Q♦ | 6♥ 7♥ 8♥ 9♥ | 5♠ 5♥ 5♦ | 10♠ 10♥ 10♦

        </p>
        <p>Sequences are very important for a rummy card game and you need minimum 2 sequences, out of which one needs to be pure sequence and the other can be pure or impure sequence to win the game. In this example, there is a pure sequence, however the 2nd sequence is missing and hence it is an invalid declaration.</p>
      </div>
    </div>
    <div>
     <h2> Handy Directions to Follow While Declaring With 13 Cards: </h2>
      <table style={{
        width: "100%",
        borderCollapse: "collapse",
        borderSpacing: "0",
        verticalAlign: "middle",
        textAlign: "center",
        margin: "10px 0 15px",
      }}>
        <tr style={
          {
            textAlign: "center"
          }
        }><th style={cellStyle}>PURE SEQUENCE</th>
          <th style={cellStyle}>IMPURE SEQUENCE</th>
          <th style={cellStyle}>SET 1 & SET 2</th></tr>
        <tr style={
          {
            textAlign: "center"
          }
        }><td style={cellStyle}>COMPULSORY to make</td>
          <td>NOT COMPULSORY
            (Can make to fulfill minimum 2 sequence requirement)</td>
          <td style={cellStyle}>NOT COMPULSORY
            (Can make to complete 13 Cards valid grouping)</td></tr>
        <tr style={
          {
            textAlign: "center"
          }
        }><td style={cellStyle}>Made with 3 or More Cards</td>
          <td style={cellStyle}>Made with 3 or More Cards</td>
          <td style={cellStyle}>Made with 3 or 4 Cards without Joker.
            OR
            Made with 3, 4 or More Cards with Joker.</td></tr>
        <tr style={
          {
            textAlign: "center"
          }
        }><td style={cellStyle}>Cards of SAME SUIT in SEQUENTIAL ORDER</td>
          <td style={cellStyle}>Cards of SAME SUIT in SEQUENTIAL ORDER with Wild Card Joker or Printed Joker</td>
          <td style={cellStyle}>Cards of SAME VALUE & DIFFERENT SUIT (2 Cards of same color but different suit can be used Ex - 5♠ 5♥ 5♦).</td></tr>
        <tr style={
          {
            textAlign: "center"
          }
        }><td style={cellStyle}>CANNOT USE JOKER or WILD CARD</td>
          <td style={cellStyle}>CAN USE JOKER or WILD CARD</td>
          <td style={cellStyle}>CAN USE JOKER or WILD CARD</td></tr>
      </table>
    </div>
  </div>

<h2>Combination Possible as per the Above Rules to Declare 13 Cards in Rummy:</h2>


<p>1.There is one Pure Sequence of 4 cards</p>
  <p>2.There is Impure Sequence of 3 cards where 8♣ is a wild joker</p>
  <p>3.There is a “Set 1” of 3 cards</p>
  <p>4.There is a “Set 2” of 3 cards having a “Printed Joker”</p>
 




<h2> How The Points Are Calculated As Per Indian Rummy Rules? </h2>
<p>Let us look how points calculation is done, when you are playing online rummy card game.</p>
<table style={{
  width: "100%",
  borderCollapse: "collapse",
  borderSpacing: "0",
  verticalAlign: "middle",
  textAlign: "center",
  margin: "10px 0 15px",
}}>
  <tr style={{ textAlign: "center" }}>
    <th style={cellStyle}>CARDS</th>
    <th style={cellStyle}>VALUE</th>
  </tr>
  <tr style={{ textAlign: "center" }}>
    <td style={cellStyle}>High value cards Ace, King, Queen, Jack</td>
    <td style={cellStyle}>All carry 10 points each</td>
  </tr>
  <tr style={{ textAlign: "center" }}>
    <td style={cellStyle}>Joker and Wild Cards</td>
    <td style={cellStyle}>Zero points</td>
  </tr>
  <tr style={{ textAlign: "center" }}>
    <td style={cellStyle}>Other cards</td>
    <td style={cellStyle}>Have point value same as their face value</td>
  </tr>
  <tr style={{ textAlign: "center" }}>
    <td style={cellStyle}>Example: 8 ♥, 9 ♥, 10 ♥</td>
    <td style={cellStyle}>8 points, 9 points, 10 points</td>
  </tr>
</table>



<h2> Losing Player Points: </h2>
<table style={{
  width: "100%",
  borderCollapse: "collapse",
  border: "1px solid black",
  textAlign: "center",
  margin: "10px 0 15px",
}}>
  <tr style={{ border: "1px solid black" }}>
    <th style={cellStyle}>Condition</th>
    <th style={cellStyle}>Points Deducted</th>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>If the player doesn’t have 2 sequences including a pure sequence</td>
    <td style={cellStyle}>Value of all cards is added, capped at 80 points</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>If the player has formed 2 sequences including pure sequence</td>
    <td style={cellStyle}>Value of cards that are not in sequence are calculated</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Wrong Declaration</td>
    <td style={cellStyle}>80 points</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>First Drop</td>
    <td style={cellStyle}>20 points</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Middle Drop</td>
    <td style={cellStyle}>40 points</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>3 Consecutive Misses</td>
    <td style={cellStyle}>Considered as middle drop with 40 points loss</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Leave Table</td>
    <td style={cellStyle}>If player leaves table after picking from closed deck, it is considered middle drop. If the player hasn’t picked any card, it is taken as first drop.</td>
  </tr>
</table>






<h2> Examples of Points Calculation With Winning Amount </h2>
<p>Example: Table of 6 Players (Wild Joker Q♦)</p>
<table style={{
  width: "100%",
  borderCollapse: "collapse",
  border: "1px solid black",
  textAlign: "center",
  margin: "10px 0 15px",
}}>
  <tr style={{ border: "1px solid black" }}>
    <th style={cellStyle}>Player</th>
    <th style={cellStyle}>Hand Formed</th>
    <th style={cellStyle}>Points Calculated</th>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Player 1</td>
    <td style={cellStyle}>2♥ 3♥ 4♥ | 5♣ 6♣ Q♦ | 8♦ 8♠ 5♣ | 2♦ 2♣ | K♠ Q♠</td>
    <td style={cellStyle}>The player has 2 sequences 1 pure and 1 impure. So, only the points of unmatched cards will be counted = 45</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Player 2</td>
    <td style={cellStyle}>4♠ 4♥ 4♣| 4♦ 5♦ Q♦ | 3♠ 7♠ 8♠ | Q♦ K♦ | 10♣ 9♣</td>
    <td style={cellStyle}>The player has not formed 2 sequences, including the pure sequence. Hence, points of all cards will be calculated = 68</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Player 3</td>
    <td style={cellStyle}>3♥ 4♥ 5♥ | 5♣ 6♣ 7♣ Q♦ | 8♦ 5♣ | 2♦ 2♣ 2♥ | K♠</td>
    <td style={cellStyle}>The player has 2 sequences, 1 pure and 1 impure. It also has 1 set formed. The points will be calculated only for the ungrouped cards = 23</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Player 4</td>
    <td style={cellStyle}>A♥ 4♥ 5♥ | 5♣ 6♣ 10♣ J♦ | 8♦ 5♣ | 2♦ 2♣ Q♥ | K♠</td>
    <td style={cellStyle}>First Drop with a loss of 20 points</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Player 5</td>
    <td style={cellStyle}>4♠ 4♥ 4♣| 4♦ 5♦ Q♦ | A♠ 7♠ 8♠ | Q♦ K♦ | J♣ 9♣</td>
    <td style={cellStyle}>3 consecutive misses = 40 points</td>
  </tr>
  <tr style={{ border: "1px solid black" }}>
    <td style={cellStyle}>Player 6</td>
    <td style={cellStyle}>2♥ 3♥ 4♥ | 5♣ 6♣ 7♣ Q♦ | 5♦ 5♣ 5♥ | 2♦ 2♣ 2♥</td>
    <td style={cellStyle}>WINNER</td>
  </tr>
</table>












<h2>How Are Your Winnings Calculated In Rummy Cash Games?</h2>
<p>At the end of the day, it is all about seeing that winning cash amount in your account. You also need to be very clear about how you are getting the sum that is reflecting in your Dashboard. Let’s help you understand how these calculations are done to play rummy online for real money.</p>

<h2>Winning Calculation in Points Rummy?</h2>
<p>When you are playing points rummy cash games, it is based on a pre-decided rupee value. The winner of the game wins the entire cash amount lost by the other players at the end of the game. Here is how the calculation is done:</p>
<p><strong>Winning Cash = (Sum of all points of opponents) X (Rupee value of the point) - RummyCircle Fee</strong></p>

<h3>Example:</h3>
<p>Total of 6 players playing points rummy for cash on Rs. 860 table. Each point has a pre-decided value of Rs. 4. There will be 1 player who will be the winner and the remaining 5 will lose the game. The losing points of the remaining 5 players would be 45, 78, 23, 20, and 40 respectively. The winnings will be calculated as:</p>
<p><strong>4 x (45+78+23+20+40) = Rs. 824</strong></p>
<p>This amount, after the deduction of the RummyCircle fee, will reflect in the player’s account.</p>

<h2>Winnings Calculation in Pool Rummy?</h2>
<p>The winnings for pool rummy are calculated as per the given calculation:</p>
<p><strong>Winnings = (Entry Fee) X (Number of Players) - RummyCircle fees</strong></p>

<h3>Example:</h3>
<p>Players pay the fixed entry fee for the tournament, which is used to form the prize pool. If 5 players join a pool rummy with Rs. 50 as an entry fee, the prize pool of the game will be Rs. 250.</p>
<p><strong>The winner will win Rs. 50 x 5 = Rs. 250</strong></p>
<p>This amount will be credited to the winner’s account after the deduction of the RummyCircle fees.</p>

<h2>Winnings Calculation in Deals Rummy?</h2>
<p>In deals rummy, the winner wins all the chips at the end of each deal. Here is how the winnings are calculated:</p>
<p><strong>Winnings = Sum of points of all opponents assuming each chip is equal to one point.</strong></p>

<h3>Example:</h3>
<p>Let’s assume that there are 6 players on the table and player 5 declares his hand. The other four players will lose with 10, 20, 30, 35, and 40 points respectively. The winner’s chips will be calculated as:</p>
<p><strong>10 + 20 + 30 + 35 + 40 = 135 chips.</strong></p>














</div>


// const Contact = () => <h2>Contact Page</h2>;

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gamerule" element={<GameRule />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/responsible-rummy" element={<ResponsibleRummy />} />
        {/* <Route path="/legality" element={<Legality />} /> */}

      </Routes>
      {/* <Contact/> */}
      <Footer />

    </Router>
  );
}

export default App;
