import React, { useEffect } from "react";

const Refund = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
      <div className="refund-container">
        <h1 className="headings">Refund and Cancellation</h1>
        <h2>Refund Policy</h2>
        <p>
        At BGM Game, we understand that mistakes can happen, and users may need to modify or delete their game plays under certain circumstances. This policy outlines the rules and procedures for deleting game plays, requesting refunds, and the conditions under which such actions are permitted. Please read this policy carefully to understand your rights and responsibilities.

1. General Policy on Game Play Deletion and Refunds
Refund Eligibility: Refunds are only applicable in specific scenarios, as outlined in this policy.


Result Declaration: Once the result for a game is declared, no deletions or refunds are allowed under any circumstances.


We shall deactivate Your Account pursuant to any direction issued by an appropriate government agency and/or competent authority. If We receive requisite instructions from such authority, We shall refund the deposited amount from Your Unutilised Account to the source account, subject to applicable processing fees.

In the event Our Services are not available due to reasons outside Our control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control, such as acts of god, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemic, epidemic, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of services available on Our Platform (each a “Force Majeure Event”), then We reserve the right to cancel any Contest and process refund of the Pre - Designated Amount.

If there is any request for withdrawal of amount deposited in Unutilised Account, such refund request shall be processed net of GST i.e. applicable 28% GST paid on the amount deposited in Unutilised Account. GST amount shall not be refunded as per applicable government laws.

2. Transparency and User Responsibility
Transaction History: Users can view all their deposite and withdraw transactions.

User Responsibility: It is the user's responsibility to ensure that their game plays are accurate and placed within the rules. BGM Game is not liable for errors caused by user negligence.

USER FUNDS

You hereby authorise Us to appoint an independent third-party/ trustee/ escrow agent to act on your behalf in a fiduciary capacity (“Third Party”) with respect to holding any amount belonging to You and undertaking actions, consents, approvals and any other requisite instructions necessary for such amount.

You acknowledge that We do not hold any right, title and/or interest in the amount deposited by You.

the amount deposited by You will be used only for participation in the Paid Contest.

any amount deposited by You, shall not be forfeited by Us except in case where We receive an instruction from any government agency or competent authority.

You further authorize Us to instruct the Third Party to open a non-interest earning bank account(s) which will be used for the following payouts:withdrawal of Your winnings; payment of Platform Fee; payment of taxes as applicable towards TDS on Net Winnings; andGovernment (toward GST payable)

3. How to Contact Support for Deletion Requests
 Please follow these steps:

Email: Send an email to support@bgmgame.co with the subject line "Game Play Deletion Request."

In-App Chat: Use the chat feature in the app to connect with our support team.

Provide Details: Include the following information in your request:

Your registered mobile number

Game play ID

Reason for deletion

Screenshot or proof (if applicable)

4. Updates to This Policy
We reserve the right to update this policy at any time. Users will be notified of significant changes via email or in-app notifications. Continued use of the platform constitutes acceptance of the updated policy.
        </p>
  <h2>Cancellation Policy</h2>
  <p>
  1. General Policy on Cancellations
Eligibility: Cancellations are only permitted under specific circumstances, as outlined in this policy.

Time Limits: Certain cancellations must be requested within a specified time frame to be eligible.

Refunds: If a cancellation is approved, any applicable refunds will be processed as per our Refund Policy.


The support team will review the request and approve the cancellation if the reason is valid (e.g., accidental selection, technical error).

If approved, the points will be refunded to the user's account.


B. Cancellation of Deposits

Before Processing: If a user initiates a deposit but wishes to cancel it before the transaction is processed, they can do so by contacting our support team immediately.

After Processing: Once a deposit is processed and the points are credited to the user's account, the transaction cannot be canceled. However, users can withdraw the amount as per our Withdrawal Policy.

C. Cancellation of Withdrawals

Before Processing: If a user initiates a withdrawal but wishes to cancel it before the transaction is processed, they can do so by contacting our support team immediately.

After Processing: Once a withdrawal is processed and the amount is transferred to the user's bank account, the transaction cannot be canceled.

D. Cancellation Due to Game Closure

If BGM Game closes a particular game due to technical issues, rule violations, or any other reason, all affected users will receive a full refund of their points.

Process:

The system will automatically cancel all game plays.

Points will be refunded to the users' accounts within 24 hours.

Users will be notified via email or in-app notification about the game closure and refund.

3. How to Request a Cancellation

For Deposits or Withdrawals:

Contact our support team immediately at support@bgmgame.co or through the in-app chat feature.

Provide Details:

Include the following information in your request:

Your registered mobile number

Transaction ID or game play ID

Reason for cancellation

Screenshot or proof (if applicable)

4. Conditions for Cancellation Approval

Cancellations are only approved under the following conditions:
The reason for cancellation is valid (e.g., accidental selection, technical error)
The user has not violated any of our Terms and Conditions.

5. Non-Cancellable Scenarios

The following transactions or activities cannot be canceled under any circumstances:

Game plays after the result has been declared.

Deposits or withdrawals that have already been processed.

Transactions or activities that violate our Terms and Conditions.

6. Refund Process for Approved Cancellations
If a cancellation is approved, any applicable refunds will be processed as follows:

Game Plays: Points will be refunded to the user's account immediately.

Deposits: The deposited amount will be refunded to the user's bank account within 3-5 business days.

Withdrawals: The withdrawal request will be canceled, and the amount will be credited back to the user's BGM Game account.

7. Transparency and User Responsibility
Transaction History: Users can view all their transactions, cancellations, and refunds in the "My Transactions" section.

User Responsibility: It is the user's responsibility to ensure that their transactions and game plays are accurate and placed within the rules. BGM Game is not liable for errors caused by user negligence.

8. Updates to This Policy
We reserve the right to update this Cancellation Policy at any time. Users will be notified of significant changes via email or in-app notifications. Continued use of the platform constitutes acceptance of the updated policy.

9. Contact Information
For any queries or concerns regarding cancellations, please contact us at:

<h2>Email: support@bgmgame.co</h2>

By using BGM Game, you agree to comply with this Cancellation Policy. We are committed to providing a fair, transparent, and user-friendly experience for all our users.

<strong>2023 © BGM Game. All rights reserved.</strong>  
  </p>
      </div>
    );
  };
  
  export default Refund ;
