import React from "react";


const About = () => <div className="about-content">
  <div className="game-heading"><h1 >ABOUT BGM GAME RUMMY</h1></div>
  <p>Welcome to BGM GAME, the flagship brand of BGM GAME PRIVATE LIMITED,
    where skill, strategy, and precision take center stage! We are a platform dedicated to
    providing an engaging and rewarding experience for
    users who enjoy testing their analytical abilities, mathematical skills, and decision-making prowess.</p>
  <h2>Our Mission</h2>
  <p>At BGM GAME, our mission is to create a platform that celebrates skill, strategy, and fair play. We aim to provide
    users with an opportunity to showcase their abilities, learn new techniques, and compete in a transparent and secure environment.
  </p>
  <h2>Our Vision</h2>
  <p>
    We envision a gaming ecosystem where users can engage in intellectually stimulating activities, improve their skills, and enjoy the thrill of strategic gameplay.
  </p>
  <div>
    <div>
      <p>This is an online rummy platform giving the Best Rummy ExperienceTM with promotions, cash prizes and entertainment.</p>
      
    </div>

    <div>
      <h2> Round the Clock Gameplay and Endless Excitement</h2>
      BGM Game is so much more than just an online game platform. It is all about playing the most challenging games of rummy blended in with surprise and excitement. There is optimism, nostalgia and a unique ambiance that’s designed to thrill you with each game. Step inside and take your seat with our cash games and tournaments with the best of rummy players from India. There is pool rummy and points rummy in the 13 card rummy variant and no matter what’s your style, there is a game for you.
    </div>
    <div>
      <h2>What is BGM Game All About</h2>
      <p>BGM Game is the ultimate rummy destination, that offers 100% legal and safe gameplay right on your mobile and laptops. Once you register with BGM Game, you enter the world where challenge and thrill are not just about a game, but a way of life! There is endless opportunity and the stronger are your skills with the game, the bigger are the rewards.</p>
    </div>
    <div>
      <h2>Safe and Fair Online Games</h2>
      <p>
        One of the concerns in online games is the guarantee of fair gameplay. To assure the integrity of our rummy card games, the trusted Random Number Generator (RNG) is always used to determine the cards that are given. In each game, RNG is utilized to ensure compliance with all required standards.

        Our games are available for both free and cash prizes. Rummy is a game of skill and doesn’t come under gambling, as stated by the Honorable Supreme Court of India. It’s completely legal to play rummy for cash.

        BGM Game is also a proud platinum member of The E-Gaming Federation (EGF), an independent, not-for-profit organization dedicated to protecting consumer interests. The EGF sets high voluntary regulatory standards for the online gaming industry in India and is established under the Societies Registration Act. It supports and guides online rummy game operators in delivering sustainable and responsible entertainment to players across India.

        Don’t wait any further. Join India’s largest Rummy website, BGM Game and play the game that is thrilling, refreshing and rewarding all at the same time.
      </p>
    </div>
    <div>

    </div>
  </div>
  <div>
    <h2>Legality</h2>

    <h3>What is gambling and how is it different from Skill Games under Indian laws?</h3>
    <p>Gambling or gaming has been defined by the Supreme Court in 1996 as betting and wagering on games of chance only. The Supreme Court in this judgment specifically excludes games of skill, irrespective of whether they are played for money or not, from the definition of gambling.</p>
    <blockquote>
      "The expression 'gaming' in the two Acts has to be interpreted in the light of the law laid-down by this Court in the two 1957 cases, wherein it has been authoritatively held that a competition which substantially depends on skill is not gambling. Gaming is the act or practice of gambling on a game of chance. It is staking on chance where chance is the controlling factor. 'Gaming' in the two Acts would, therefore, mean wagering or betting on games of chance. It would not include games of skill like horse racing."
    </blockquote>
    <p>The Public Gambling Act and subsequent state laws state that "nothing in this Act shall apply to games of mere skill wherever played".</p>
    <blockquote>
      "In any case...Section 11 of the Gaming Act specifically saves the games of mere skill from the penal provisions of the two Acts."
    </blockquote>

    <h3>What is a game of skill under Indian laws?</h3>
    <p>The Supreme Court of India in 1996 defined a game of mere skill as follows:</p>
    <p>The competitions where success depends on a substantial degree of skill are not "gambling". Despite there being an element of chance, if a game is preponderantly a game of skill, it would nevertheless be a game of "mere skill".</p>
    <p>Thus, the expression "mere skill" means a substantial degree or preponderance of skill.</p>

    <h3>Is rummy a game of skill?</h3>
    <p>The 1968 Supreme Court ruling declared rummy to be a game of skill:</p>
    <blockquote>
      "Rummy, on the other hand, requires a certain amount of skill because the fall of the cards has to be memorised and the building up of Rummy requires considerable skill in holding and discarding cards. We cannot, therefore, say that the game of Rummy is a game of entire chance. It is mainly and preponderantly a game of skill. The chance in Rummy is of the same character as the chance in a deal at a game of bridge."
    </blockquote>
    <p>Further, the Supreme Court in 1996 stated:</p>
    <blockquote>
      "A game of skill, on the other hand - although the element of chance necessarily cannot be entirely eliminated --is one in which success depends principally upon the superior knowledge, training, attention, experience and adroitness of the player. Golf, chess and even Rummy are considered to be games of skill. The Courts have reasoned that there are few games, if any, which consist purely of chance or skill, and as such a game of chance is one in which the element of chance predominates over the element of skill, and a game of skill is one in which the element of skill predominates over the element of chance. It is the dominant element --"skill" or "chance" -- which determines the character of the game."
    </blockquote>

    <h3>Do games of skill enjoy any other protection legally?</h3>
    <p>Yes, in 1957, the Supreme Court stated that prize competitions which involve substantial skill are business activities protected under Article 19(1)(g) of the Constitution of India.</p>

    <h3>Is it legal to play rummy for cash on bgmgame.co?</h3>
    <p>The various Supreme Court rulings and the Gaming Acts of India imply the following:</p>
    <p>Gaming or gambling means betting and wagering on games of chance. Playing games of skill for cash does not constitute gambling. Games of skill are exempt from the penal provisions of most gambling acts. Rummy is a game of skill.</p>
    <p>So, yes, it is perfectly legal to play rummy for cash on bgmgame.co as long as you are not playing from the states of Assam, Telangana, Andhra Pradesh, Nagaland, and Sikkim. As we get more clarity on the laws in these states, we might consider offering our services to residents of these states as well.</p>

  </div>
  <h2>Customer Support
  </h2>
  <p>
    We are dedicated to providing exceptional customer support. For assistance, contact us at <b>support@bgmgame.co.</b>
  </p>
  <h2>Contact Us</h2>
  <p>
    <b>Merchant Legal Entity Name:</b> BGM GAME PRIVATE LIMITED
    <b>Operational Address:</b> Dev Associates, RIICO INDUSTRIAL AREA Gheeloth, Alwar, Rajasthan, 301705
    <b>Email ID: </b>support@bgmgame.co
  </p>
  <h2>Join Us Today!</h2>
  <p>
    If you enjoy games that challenge your mind, test your skills, and reward your strategic thinking,
    BGM GAME is the perfect platform for you. Join us today and experience the thrill of skill-based gaming.
  </p>

</div >;

export default About;
